import { useContext } from 'react';
import CountryButtons from 'components/CountryButtons';
import { countries } from 'configs/countries';
import Context from 'context/CountryContext';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/public/TradeInGoods';

import styles from '../Page.module.scss';

const TradeInGoodsSC = () => {
  const { currentCountry } = useContext(Context);

  const countrySet = ['Russia', 'Belarus', 'Armenia', 'Kazakhstan', 'Kyrgyzstan'];

  const countrySetSecondPlusUkr = [
    'Azerbaijan',
    'Georgia',
    'Moldova',
    'Tajikistan',
    'Uzbekistan',
    'Turkmenistan',
    'Ukraine',
  ];

  const countrySetCommoditiesTradeYearly = [
    'Russia',
    'Belarus',
    'Armenia',
    'Kyrgyzstan',
    'Kazakhstan',
    'Azerbaijan',
    'Moldova',
    'Tajikistan',
    'Uzbekistan',
    'Turkmenistan',
    'Ukraine',
  ];

  return (
    <>
      <h1 id="_1">Торговля товарами</h1>
      <CountryButtons
        countries={[
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Russia,
          countries.Azerbaijan,
          countries.Georgia,
          countries.Moldova,
          countries.Tajikistan,
          countries.Turkmenistan,
          countries.Uzbekistan,
          countries.Ukraine,
          countries.Abkhazia,
          countries.SouthOssetia,
        ]}
      />
      {/* Новые графики по состоянию на август 2023 из нац источников для России, Армении, Белоруси, Казахстана, Кыргызстана */}
      {countrySet.includes(currentCountry) && (
        <>
          <div className={styles.page_section}>
            <DynamicChart
              toggles={ChartConfigs.CommoditiesTradeDynamicNatSources[currentCountry]}
            />
          </div>

          {/* Следующий график разделен на Беларусь и остальных так как у Беларуси нет Актуальные */}

          {currentCountry !== 'Belarus' && (
            <div className={styles.page_section}>
              <DynamicChart
                toggles={ChartConfigs.Top10ByValueVolumeNatSources[currentCountry]}
                filters={['Актуальные', 'По годам']}
              />
            </div>
          )}
          {currentCountry === 'Belarus' && (
            <div className={styles.page_section}>
              <DynamicChart toggles={ChartConfigs.Top10ByValueVolumeNatSources[currentCountry]} />
            </div>
          )}

          {/* _______________________________________________________________________________________ */}

          <div className={styles.page_section}>
            <DynamicChart
              toggles={ChartConfigs.ProductTradeGroupsShareNatSources[currentCountry]}
              filters={['Доли', 'Объем']}
            />
          </div>

          <div className={styles.page_section}>
            <DynamicChart
              toggles={ChartConfigs.commoditiesTradeYearlyWithEAEUNatSources[currentCountry]}
            />
          </div>

          <div className={styles.page_section}>
            <DynamicChart
              toggles={ChartConfigs.WithEAEUStructYearNatSources[currentCountry]}
              filters={['Доли', 'Объем']}
            />
          </div>

          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.WithEAEUStructLastYearNatSources[currentCountry]} />
          </div>
        </>
      )}
      {/* _______________________________________________________________________________________ */}
      {/* Графики Азербайджан */}
      {currentCountry === 'Azerbaijan' && (
        <div className={styles.page_section}>
          <DynamicChart
            toggles={ChartConfigs.ProductTradeDynamicAze}
            filters={['По годам', 'По кварталам']}
          />
        </div>
      )}
      {/* _______________________________________________________________________________________ */}
      {countrySetSecondPlusUkr.includes(currentCountry) && (
        <>
          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.CommoditiesTradeDynamic[currentCountry]} />
          </div>
          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.CommoditiesTradeWithRus[currentCountry]} />
          </div>
          <div className={styles.page_section}>
            <DynamicChart
              toggles={ChartConfigs.ProductGroups[currentCountry]}
              filters={['Доли', 'Объем']}
            />
          </div>
          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.TradeGroupsWithRuLast[currentCountry]} />
          </div>
        </>
      )}
      {/* _______________________________________________________________________________________ */}
      {/* Графики, которые не попали в группировку */}
      {/* Графики Украина */}

      {currentCountry === 'Ukraine' && (
        <div className={styles.page_section}>
          <DynamicChart
            toggles={ChartConfigs.Top10ByValueVolumeUnionState[currentCountry]}
            filters={['Актуальные', 'По годам']}
          />
        </div>
      )}
      {/* график ниже добавлен в связи с необходимостью изменить кнопку фильтра для Азербайджана на 2023 год, сверху соотв скрыт */}
      {currentCountry === 'Azerbaijan' && (
        <div className={styles.page_section}>
          <DynamicChart
            toggles={ChartConfigs.Top10ByValueVolumeUnionStateAze}
            filters={['За 2023 год', 'По годам']}
          />
        </div>
      )}
      {(currentCountry === 'Georgia' ||
        currentCountry === 'Moldova' ||
        currentCountry === 'Tajikistan' ||
        currentCountry === 'Uzbekistan' ||
        currentCountry === 'Turkmenistan' ||
        currentCountry === 'Ukraine') && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.YearlyTop10[currentCountry]} />
        </div>
      )}

      {countrySetCommoditiesTradeYearly.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.CommoditiesTradeYearly[currentCountry]} />
        </div>
      )}
      {/* Графики, которые не попали в группировку */}
      {/* Графики Абхазия */}
      {currentCountry === 'Abkhazia' && (
        <>
          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.YearlyCommoditiesTradeWithRuAbh} />
          </div>

          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.YearlyCommoditiesTradeAbh} />
          </div>

          {/* графики ниже скрыты по просьбе ИРИП от 09.08.2023 */}

          {/* <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.YearlyCommoditiesStructWithRuAbh} />
          </div>

          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.YearlyCommoditiesStructWithTrAbh} />
          </div> */}
        </>
      )}
      {/* Графики Южная Осетия */}
      {currentCountry === 'SouthOssetia' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.YearlyRuCommoditiesTradeSO} />
        </div>
      )}
    </>
  );
};

export default TradeInGoodsSC;
