import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import Highcharts from 'highcharts';
import axios from 'axios';
import { debounce } from 'lodash';
import {
  BarChart,
  HBarChart,
  HMBarChart,
  SplineBarChart,
  SplineChartOneY,
  SplineChartTwoY,
  MBarChartOneY,
  MBarLineChartTwoY,
} from 'frontend-react-library/lib/Charts';
import { Tooltip } from 'frontend-react-library/lib/ui/Tooltip';

import Skeleton from '@material-ui/lab/Skeleton';
import CountryContext from 'context/CountryContext';
import AuthUserContext from 'context/AuthUser';
import classnames from 'classnames';
import { ReactComponent as FullScreenIMG } from 'assets/img/fullscreen.svg';
import { ReactComponent as CloseScreenIMG } from 'assets/icons/close.svg';
import { ReactComponent as EditChartIMG } from 'assets/img/chart-edit.svg';
import { URL } from 'services/URL';
import styles from './DynamicChart.module.scss';
import ChartStatus from './ChartStatus/ChartStatus';
import Modal from '../ui/Modal/Modal';
import ChartEdit from './Modal/ChartEdit';

axios.defaults.baseURL = URL;

const defaultChartOptions = {
  titleEnabled: false,
  defaultExport: false,
};

const getURL = (axiosParams) => {
  const { url } = axiosParams;
  const { params } = axiosParams;
  let paramsStr = '?';
  if (params) {
    const keys = Object.keys(params);
    for (let i = 0; i < keys.length; i++) {
      if (keys.length > 1) {
        if (i === 0) {
          paramsStr += `${keys[i]}=${params[keys[i]]}`;
        } else {
          paramsStr += `&${keys[i]}=${params[keys[i]]}`;
        }
      } else {
        paramsStr += `${keys[i]}=${params[keys[i]]}`;
      }
    }
  }
  return `/${url}${paramsStr}`;
};

const DynamicChart: React.FC<{
  toggles: Array<{
    title: string;
    chart: string;
    chartOptions: any;
    filter?: string;
    request: {
      method: string;
      url: string;
      params?: any;
    };
  }>;
  filters?: string[];
}> = ({ toggles, filters }) => {
  const { currentCountry } = useContext(CountryContext);
  // const { isAuthUser } = useContext(AuthUserContext);

  const [editChart, setEditChart] = useState(false);

  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [activeFilter, setActiveFilter] = useState(filters ? filters[0] : '');

  const [data, setData] = useState<{
    data: any;
    loading: boolean;
    error: any;
  }>({ data: null, loading: true, error: null });
  const [chartOptions, setChartOptions] = useState<{
    type: string | null;
    options: any | null;
  }>({
    type: null,
    options: null,
  });
  const [activeChart, setActiveChart] = useState<{
    data: { data: any; loading: boolean; error: any };
    type: string | null;
    options: any | null;
  }>({
    data: { data: null, loading: true, error: null },
    type: null,
    options: null,
  });

  const [activeToggles, setActiveToggles] = useState<
    Array<{
      title: string;
      chart: string;
      chartOptions: any;
      request: {
        method: string;
        url: string;
        params?: any;
      };
    }>
  >([]);

  const [repeatRequest, setRepeatRequest] = useState(0);

  const [uploadedData, setUploadedData] = useState({});
  const dynChartRef = useRef<HTMLDivElement>(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [defaultHeight, setDefaultHeight] = useState(470);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [chartURL, setChartURL] = useState('');

  const debounceFn = useCallback(
    debounce(() => {
      Highcharts.charts.forEach((chart: any) => {
        chart?.reflow();
        chart?.setSize(undefined, undefined);
      });
    }, 200),
    []
  );

  const updHeight = () => {
    let height;
    if (window.outerHeight > 700) {
      height = window.innerHeight - window.innerHeight * 0.4;
    } else {
      height = window.innerHeight;
    }

    return height;
  };

  const refreshChart = () => {
    setUploadedData({});
    setRepeatRequest(Math.random());
  };

  useEffect(() => {
    const func = () => {
      if (!document.fullscreenElement) {
        setFullScreen(false);
        debounceFn();
      }
    };
    document.addEventListener('fullscreenchange', func);
    return () => {
      document.removeEventListener('fullscreenchange', func);
    };
  }, []);

  useEffect(() => {
    if (!fullScreen) {
      const options = { ...activeChart };
      if (options.options) {
        options.options.height = defaultHeight;
        setActiveChart({ ...options });
        setChartOptions({ ...options });
      }
      window.scrollTo(0, scrollPosition);
    }
  }, [fullScreen]);

  useEffect(() => {
    if (toggles) {
      const togglesCopy = JSON.parse(JSON.stringify(toggles));
      if (!activeTab) {
        setActiveTab(togglesCopy[0].title);
        setChartOptions({ type: togglesCopy[0].chart, options: togglesCopy[0].chartOptions });
      }
      if (!filters) {
        if (
          activeToggles.length !== togglesCopy.length ||
          activeToggles[0].title !== togglesCopy[0].title
        ) {
          setActiveTab(togglesCopy[0].title);
          setChartOptions({ type: togglesCopy[0].chart, options: togglesCopy[0].chartOptions });
        }
        setActiveToggles(togglesCopy);
      } else {
        const obj = JSON.parse(JSON.stringify(togglesCopy));
        const objFilter = obj.filter((item) => item.filter === activeFilter);
        setActiveToggles(objFilter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggles]);

  useEffect(() => {
    let axiosParams = {};
    let cleanupFunction = false;
    if (activeTab || activeTab === '') {
      const obj = JSON.parse(JSON.stringify(toggles));
      if (filters) {
        const objFilter = obj.filter((item) => item.filter === activeFilter);
        axiosParams = objFilter.filter((item) => item.title === activeTab)[0].request;
        setChartURL(getURL(axiosParams));
      } else {
        axiosParams =
          toggles.length > 1
            ? obj.filter((item) => item.title === activeTab)[0].request
            : toggles[0].request;
        setChartURL(getURL(axiosParams));
      }

      const fetchData = async () => {
        try {
          const result = await axios.request(axiosParams);
          if (!cleanupFunction) {
            const dataObj = { data: result.data, loading: false, error: null };
            setData({ ...JSON.parse(JSON.stringify(dataObj)) });
            const uplData = JSON.parse(JSON.stringify(uploadedData));

            if (filters) {
              if (activeTab) {
                uplData[currentCountry] = { ...uplData[currentCountry] };
                uplData[currentCountry][activeFilter] = {
                  ...uplData[currentCountry][activeFilter],
                };
                uplData[currentCountry][activeFilter][activeTab] = {
                  data: result.data,
                  loading: false,
                  error: null,
                };
              }
            } else if (activeTab) {
              uplData[currentCountry] = { ...uplData[currentCountry] };
              uplData[currentCountry][activeTab] = {
                data: result.data,
                loading: false,
                error: null,
              };
            } else {
              uplData[currentCountry] = {
                data: result.data,
                loading: false,
                error: null,
              };
            }
            setUploadedData(uplData);
          }
        } catch (error) {
          if (!cleanupFunction) setData({ data: null, loading: false, error });
        }
      };

      let flag;

      if (filters) {
        flag = uploadedData?.[currentCountry]?.[activeFilter]?.[activeTab];
      } else if (activeTab) {
        flag = uploadedData?.[currentCountry]?.[activeTab];
      } else {
        flag = uploadedData?.[currentCountry];
      }

      if (flag !== undefined) {
        const dataObj = JSON.parse(JSON.stringify(flag));
        setData({ ...dataObj });
      } else {
        fetchData();
      }
    }
    return () => {
      cleanupFunction = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter, activeTab, currentCountry, repeatRequest]);

  useEffect(() => {
    const chartOptionsCopy = JSON.parse(JSON.stringify(chartOptions));
    setActiveChart({ data, options: chartOptionsCopy.options, type: chartOptionsCopy.type });
  }, [data]);

  useEffect(() => {
    const updateSize = () => {
      if (fullScreen) {
        const options = { ...activeChart };
        if (options.options) {
          options.options.height = updHeight();
          setActiveChart({ ...options });
        }
      }
    };
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [activeChart]);

  const onChangeTab = (item) => {
    const itemCopy = JSON.parse(JSON.stringify(item));
    setActiveTab(itemCopy.title);
    if (fullScreen) {
      setChartOptions({
        type: itemCopy.chart,
        options: {
          ...itemCopy.chartOptions,
          height: updHeight(),
        },
      });
    } else {
      setChartOptions({
        type: itemCopy.chart,
        options: { ...itemCopy.chartOptions },
      });
    }
  };

  const onChangeFilter = (item) => {
    setActiveFilter(item);
    const obj = JSON.parse(JSON.stringify(toggles));
    const objFilter = obj.filter((el) => el.filter === item);
    const currentChart = objFilter.filter((el) => el.title === activeTab)[0];
    setActiveToggles(objFilter);
    if (fullScreen) {
      setChartOptions({
        type: currentChart.chart,
        options: {
          ...currentChart.chartOptions,
          height: updHeight(),
        },
      });
    } else {
      setChartOptions({
        type: currentChart.chart,
        options: { ...currentChart.chartOptions },
      });
    }
  };

  const returnTabs = activeToggles.map((item) => {
    return (
      <div
        key={item.title}
        onClick={() => onChangeTab(item)}
        onKeyPress={() => onChangeTab(item)}
        role="button"
        tabIndex={0}
        className={
          activeTab === item.title
            ? classnames(styles.dynamic_chart_tabs_item, styles.active_tab)
            : styles.dynamic_chart_tabs_item
        }
      >
        {item.title}
      </div>
    );
  });

  const returnFilterButtons =
    filters &&
    filters.length > 1 &&
    filters.map((item) => {
      return (
        <div
          key={item}
          onClick={() => onChangeFilter(item)}
          onKeyPress={() => onChangeFilter(item)}
          role="button"
          tabIndex={0}
          className={
            activeFilter === item
              ? classnames(styles.filter_button_item, styles.active_filter)
              : styles.filter_button_item
          }
        >
          {item}
        </div>
      );
    });

  const toggleFullScreen = () => {
    debounceFn();
    if (document.fullscreenElement) {
      document.exitFullscreen();
      const options = { ...activeChart };
      if (options.options) {
        options.options.height = defaultHeight;
        setActiveChart({ ...options });
      }
    } else if (dynChartRef && dynChartRef.current) {
      setScrollPosition(window.scrollY);
      setFullScreen(true);
      const options = { ...activeChart };
      if (options.options) {
        if (options.options?.height) {
          setDefaultHeight(options.options.height);
        }
        options.options.height = updHeight();
        setActiveChart({ ...options });
      }
      dynChartRef.current.requestFullscreen();
    }
  };

  const getActiveChart = (chartType) => {
    const chartProps = {
      ...activeChart.options,
      ...defaultChartOptions,
      data: activeChart.data.data,
      fullScreen,
    };
    switch (chartType) {
      case 'BarChart':
        return <BarChart {...chartProps} />;
      case 'HBarChart':
        return <HBarChart {...chartProps} />;
      case 'HMBarChart':
        return <HMBarChart {...chartProps} />;
      case 'SplineBarChart':
        return <SplineBarChart {...chartProps} />;
      case 'SplineChartOneY':
        return <SplineChartOneY {...chartProps} />;
      case 'SplineChartTwoY':
        return <SplineChartTwoY {...chartProps} />;
      case 'MBarChartOneY':
        return <MBarChartOneY {...chartProps} />;
      case 'MBarLineChartTwoY':
        return <MBarLineChartTwoY {...chartProps} />;
      default:
        return <div>Неверный тип графика</div>;
    }
  };

  return (
    <div
      className={
        fullScreen ? classnames(styles.dynamic_chart, styles.fullscreen) : styles.dynamic_chart
      }
      ref={dynChartRef}
      data-test-id="chart"
    >
      <div
        className={
          fullScreen
            ? classnames(styles.dynamic_chart_title, styles.dynamic_chart_title_fullscreen)
            : styles.dynamic_chart_title
        }
      >
        {activeChart.data.loading || activeChart.data.error ? (
          <>
            <Skeleton animation="wave" variant="text" height={30} className="chart-loader-text" />
            <Skeleton animation="wave" variant="text" height={30} className="chart-loader-text" />
          </>
        ) : (
          <>
            <div className={styles.dynamic_chart_main_title}>
              {activeChart.data.data?.title?.text}
            </div>
            <div className={styles.dynamic_chart_subtitle}>
              {activeChart.data.data?.subtitle?.text}
            </div>
          </>
        )}
      </div>
      {activeToggles.length > 1 && (
        <div className={styles.dynamic_chart_nav}>
          <div className={styles.dynamic_chart_tabs}>{returnTabs}</div>
          {filters && <div className={styles.filter_buttons}>{returnFilterButtons}</div>}
        </div>
      )}
      {activeChart.data.data && (
        <div
          className={classnames(
            styles.dynamic_chart_actions,
            fullScreen && styles.dynamic_chart_actions_fs
          )}
        >
          {!fullScreen &&
            process.env.REACT_APP_ROUTERS === 'full_routers' &&
            process.env.REACT_APP_URL === 'dev' && (
              <Tooltip text="Редактировать график">
                <EditChartIMG className={styles.edit_chart} onClick={() => setEditChart(true)} />
              </Tooltip>
            )}
          {fullScreen ? (
            <Tooltip position="right" text="Закрыть полноэкранный режим">
              <CloseScreenIMG
                className={styles.close_fullscreen}
                onClick={() => toggleFullScreen()}
              />
            </Tooltip>
          ) : (
            <Tooltip position="right" text="Полноэкранный режим">
              <FullScreenIMG onClick={() => toggleFullScreen()} />
            </Tooltip>
          )}
        </div>
      )}
      <Modal isModalVisible={editChart} onClose={() => setEditChart(false)}>
        <ChartEdit
          data={data}
          name={chartURL}
          refreshChart={() => refreshChart()}
          closeModal={() => setEditChart(false)}
        />
      </Modal>
      <ChartStatus status={activeChart.data} repeatRequest={setRepeatRequest}>
        {getActiveChart(activeChart.type)}
      </ChartStatus>
    </div>
  );
};

export default React.memo(DynamicChart);
